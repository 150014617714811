import React, { useState } from 'react';
import ContactForm from '../components/ContactForm';
import AnimatedComponent from '../components/AnimatedComponent';
import { Building2Icon, PhoneIcon, MailIcon, CheckCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import SectionHeader from '../components/SectionHeader';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/ScrollToTop';

const Contact = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility

  const handleFormSubmit = () => {
    setIsPopupOpen(true); // Open the popup when the form is submitted
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  return (
    <section className="min-h-screen font-poppins bg-gray-100 mt-14">
      <Helmet>
        <title>Contact Us - Drashti Foods</title>
        <meta name="description" content="Get in touch with Drashti Foods for inquiries, feedback, or support. We are here to help!" />
      </Helmet>
      <ScrollToTop />
      <SectionHeader title="CONTACT US" />
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-lg shadow-2xl shadow-black overflow-hidden"
        >
          <div className="md:flex">
            {/* Contact Information Section */}
            <div className="md:w-1/2 bg-yellow-400 p-8 text-black">
              <AnimatedComponent>
                <h1 className="text-4xl font-bold mb-6">Get in Touch</h1>
              </AnimatedComponent>
              <AnimatedComponent delay={0.2}>
                <p className="mb-6 text-lg">
                  We’re excited to hear from you! Fill out the form, and let's start a conversation about how we can help your business grow.
                </p>
              </AnimatedComponent>
              <div className="space-y-4">
                <AnimatedComponent delay={0.4}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="flex items-center"
                  >
                    <Building2Icon className="h-6 w-6 mr-2 text-yellow-600" />
                    <span className="text-black">Plot No. 81, R.K. Industrial Zone, Raj samadhiyala, Bhavnagar Highway, Rajkot</span>
                  </motion.div>
                </AnimatedComponent>
                <AnimatedComponent delay={0.5}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="flex items-center"
                  >
                    <PhoneIcon className="h-6 w-6 mr-2 text-yellow-600" />
                    <span className="text-black">+91 9825184844</span>
                  </motion.div>
                </AnimatedComponent>
                <AnimatedComponent delay={0.6}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="flex items-center"
                  >
                    <MailIcon className="h-6 w-6 mr-2 text-yellow-600" />
                    <span className="text-black">drashtifoods5818@gmail.com</span>
                  </motion.div>
                </AnimatedComponent>
              </div>
            </div>

            {/* Contact Form Section */}
            <div className="md:w-1/2 p-8 bg-white">
              <AnimatedComponent delay={0.3}>
                {/* Pass the handleFormSubmit to ContactForm */}
                <ContactForm onFormSubmit={handleFormSubmit} />
              </AnimatedComponent>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Popup/Modal */}
      {isPopupOpen && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center"
    >
      <div className="flex justify-center mb-4">
        <CheckCircle className="h-12 w-12 text-yellow-500" />
      </div>
      <h2 className="text-3xl font-bold mb-2 text-yellow-600">Thank You!</h2>
      <p className="text-gray-700 mb-4">We Got Your Information. We'll get back to you shortly.</p>
      <motion.button 
        onClick={closePopup}
        className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform shadow-lg"
      >
        Close
      </motion.button>
    </motion.div>
  </div>
)}
    </section>
  );
};

export default Contact;
