import React, { useState } from 'react';
import { toast } from 'sonner'; // For notifications
import emailjs from 'emailjs-com'; // Import EmailJS
import { PhoneIcon, MailIcon } from 'lucide-react'; // Icons for contact information
import ScrollToTop from '../components/ScrollToTop';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react'; // Import checkmark icon

const ExportInquiry = () => {
  const [formData, setFormData] = useState({
    exportFullName: '',
    exportAddress: '',
    exportCity: '',
    exportPhone: '',
    exportEmail: '',
    productType: '',
    quantity: '',
    destinationCountry: '',
    shippingMethod: '',
    exportComments: '',
    formType: 'exportInquiry', // Set form type for email template
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [loading, setLoading] = useState(false); // Track loading state
  const [showThankYou, setShowThankYou] = useState(false); // Track showing thank you modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitted) {
      toast.error("You have already submitted the form!");
      return;
    }

    setLoading(true); // Set loading state to true

    // Sending email using EmailJS
    emailjs.send('drashti_foods', 'template_b07m2kg', formData, 'OTiHIGlSMQqreou0s')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success("Form submitted successfully and email sent!");
        setIsSubmitted(true); // Disable form after submission
        setShowThankYou(true); // Show Thank You popup
      })
      .catch((error) => {
        console.log('FAILED...', error);
        toast.error("Failed to send email. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  const closeThankYouPopup = () => {
    setShowThankYou(false); // Close the Thank You popup
  };

  return (
    <div className="min-h-screen font-poppins flex flex-col mt-6">
      <ScrollToTop />
      <section className="bg-gray-50">
        <div className="min-h-screen py-6 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-8">
              <h1 className="text-4xl font-bold mb-6 text-center text-yellow-600">Export Inquiry</h1>

              {/* Contact Information Section */}
              <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-4 text-center">We're Here to Help!</h2>
                <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 text-center sm:text-left">
                  <div className="flex items-center justify-center sm:justify-start">
                    <PhoneIcon className="h-5 w-5 text-yellow-600 mr-2" />
                    <span className="text-gray-700">+91 9825184844</span>
                  </div>
                  <div className="flex items-center justify-center sm:justify-start">
                    <MailIcon className="h-5 w-5 text-yellow-600 mr-2" />
                    <span className="text-gray-700">drashtifoods5818@gmail.com</span>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {[
                    { label: 'Full Name', name: 'exportFullName', type: 'text', placeholder: 'Enter your full name' },
                    { label: 'Address', name: 'exportAddress', type: 'text', placeholder: 'Enter your address' },
                    { label: 'City', name: 'exportCity', type: 'text', placeholder: 'Enter your city' },
                    { label: 'Phone Number', name: 'exportPhone', type: 'tel', placeholder: 'Enter your phone number' },
                    { label: 'Email Address', name: 'exportEmail', type: 'email', placeholder: 'Enter your email address' },
                    { label: 'Product Type', name: 'productType', type: 'text', placeholder: 'Enter product type' },
                    { label: 'Quantity', name: 'quantity', type: 'number', placeholder: 'Enter quantity' },
                    { label: 'Destination Country', name: 'destinationCountry', type: 'text', placeholder: 'Enter destination country' },
                    { label: 'Shipping Method', name: 'shippingMethod', type: 'text', placeholder: 'Enter shipping method' },
                  ].map(({ label, name, type, placeholder }) => (
                    <div key={name} className="flex flex-col">
                      <label htmlFor={name} className="text-sm font-medium text-gray-700">{label}</label>
                      <input
                        type={type}
                        id={name}
                        name={name}
                        value={formData[name]}
                        onChange={handleChange}
                        required
                        disabled={isSubmitted || loading}
                        placeholder={placeholder} // Added placeholder
                        className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
                      />
                    </div>
                  ))}
                </div>

                <div className="flex flex-col">
                  <label htmlFor="exportComments" className="text-sm font-medium text-gray-700">Additional Comments</label>
                  <textarea
                    id="exportComments"
                    name="exportComments"
                    value={formData.exportComments}
                    onChange={handleChange}
                    required
                    disabled={isSubmitted || loading}
                    rows="4"
                    placeholder="Enter any additional comments" // Added placeholder
                    className="mt-1 w-full border border-yellow-400 focus:border-yellow-500 focus:ring-yellow-500 px-4 py-3 rounded-md text-gray-800 transition duration-150 ease-in-out"
                    style={{ height: '100px' }} // Set a uniform height for the textarea
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitted || loading}
                  className={`w-full ${isSubmitted || loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-yellow-400 hover:bg-yellow-500'} text-black font-semibold py-2 rounded-md transition duration-300 ease-in-out`}
                >
                  {loading ? 'Submitting...' : isSubmitted ? 'Submitted' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Thank You Popup Modal */}
      {showThankYou && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center"
          >
            <div className="flex justify-center mb-4">
              <CheckCircle className="h-12 w-12 text-yellow-500" />
            </div>
            <h2 className="text-3xl font-bold mb-2 text-yellow-600">Thank You!</h2>
            <p className="text-gray-700 mb-4">Your Export Inquiry has been submitted successfully. We'll get back to you shortly.</p>
            <motion.button 
              onClick={closeThankYouPopup}
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform shadow-lg"
            >
              Close
            </motion.button>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ExportInquiry;
