import React, { useState } from 'react';
import { toast } from 'sonner';
import emailjs from 'emailjs-com'; // Import EmailJS
import { PhoneIcon, MailIcon } from 'lucide-react'; // Icons for contact information
import ScrollToTop from '../components/ScrollToTop';
import { motion } from 'framer-motion';

const SupplierPage = () => {
  const [formData, setFormData] = useState({
    supplierCompany: '',
    supplierContactPerson: '',
    supplierEmail: '',
    supplierPhone: '',
    supplierProductType: '',
    supplyCapacity: '',
    leadTime: '',
    supplierAdditionalInfo: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [loading, setLoading] = useState(false); // Track loading state
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Manage popup visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitted) {
      toast.error("You have already submitted the form!");
      return;
    }

    setLoading(true); // Set loading state to true

    // Sending email using EmailJS
    emailjs.send('drashti_foods', 'template_b07m2kg', formData, 'OTiHIGlSMQqreou0s')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success("Supplier form submitted successfully and email sent!");
        setIsSubmitted(true); // Disable form after submission
        setIsPopupOpen(true); // Open the popup
      })
      .catch((error) => {
        console.log('FAILED...', error);
        toast.error("Failed to send email. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  return (
    <div className="min-h-screen font-poppins flex flex-col mt-6">
      <ScrollToTop />
      <section className="bg-gray-100">
        <div className="min-h-screen py-6 px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto bg-white rounded-lg shadow-2xl shadow-black overflow-hidden"
          >
            <div className="p-8">
              <h1 className="text-4xl font-bold mb-6 text-center text-yellow-600">Supplier Form</h1>

              {/* Contact Information Section */}
              <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-4 text-center">We're Here to Help!</h2>
                <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 text-center sm:text-left">
                  <div className="flex items-center justify-center sm:justify-start">
                    <PhoneIcon className="h-5 w-5 text-yellow-600 mr-2" />
                    <span className="text-gray-700">+91 9825184844</span>
                  </div>
                  <div className="flex items-center justify-center sm:justify-start">
                    <MailIcon className="h-5 w-5 text-yellow-600 mr-2" />
                    <span className="text-gray-700">drashtifoods5818@gmail.com</span>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {[ 
                    { label: 'Company Name', name: 'supplierCompany', type: 'text', placeholder: 'Enter your company name' },
                    { label: 'Contact Person', name: 'supplierContactPerson', type: 'text', placeholder: 'Enter contact person name' },
                    { label: 'Email Address', name: 'supplierEmail', type: 'email', placeholder: 'Enter your email address' },
                    { label: 'Phone Number', name: 'supplierPhone', type: 'tel', placeholder: 'Enter your phone number' },
                    { label: 'Product Type', name: 'supplierProductType', type: 'text', placeholder: 'Enter type of product' },
                    { label: 'Supply Capacity', name: 'supplyCapacity', type: 'text', placeholder: 'Enter supply capacity' },
                    { label: 'Lead Time', name: 'leadTime', type: 'text', placeholder: 'Enter lead time' },
                  ].map(({ label, name, type, placeholder }) => (
                    <div key={name} className="flex flex-col">
                      <label htmlFor={name} className="text-sm font-medium text-gray-700">{label}</label>
                      <input
                        type={type}
                        id={name}
                        name={name}
                        value={formData[name]}
                        onChange={handleChange}
                        required
                        disabled={isSubmitted || loading}
                        placeholder={placeholder}
                        className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
                      />
                    </div>
                  ))}
                </div>

                <div className="flex flex-col">
                  <label htmlFor="supplierAdditionalInfo" className="text-sm font-medium text-gray-700">Additional Information</label>
                  <textarea
                    id="supplierAdditionalInfo"
                    name="supplierAdditionalInfo"
                    value={formData.supplierAdditionalInfo}
                    onChange={handleChange}
                    disabled={isSubmitted || loading}
                    rows="4"
                    placeholder="Enter any additional information here"
                    className="mt-1 w-full border border-yellow-400 focus:border-yellow-500 focus:ring-yellow-500 px-4 py-3 rounded-md text-gray-800 transition duration-150 ease-in-out"
                    style={{ height: '100px' }} // Set a uniform height for the textarea
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitted || loading}
                  className={`w-full ${isSubmitted || loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-yellow-400 hover:bg-yellow-500'} text-black font-semibold py-2 rounded-md transition duration-300 ease-in-out`}
                >
                  {loading ? 'Submitting...' : isSubmitted ? 'Submitted' : 'Submit'}
                </button>
              </form>

              {/* Popup/Modal */}
              {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                    <h2 className="text-lg font-bold mb-4 text-center">Success!</h2>
                    <p className="mb-4 text-center">Your supplier form has been submitted successfully!</p>
                    <div className="flex justify-center">
                      <button 
                        onClick={closePopup}
                        className="bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default SupplierPage;
