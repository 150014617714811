import React, { useState } from 'react';
import { toast } from "sonner";
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com'; // Import EmailJS
import { User, Mail, Home, Phone } from 'lucide-react'; // Import icons from Lucide

const ContactForm = ({onFormSubmit}) => {
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    city: '',
    contactNo: '',
    email: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [loading, setLoading] = useState(false); // Track loading state
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Manage popup visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitted) {
      toast.error("You have already submitted the form!");
      return;
    }

    setLoading(true); // Set loading state to true

    // Sending email using EmailJS
    emailjs.send('drashti_foods', 'template_78as31d', formData, 'OTiHIGlSMQqreou0s')
      .then((response) => {
        toast.success("Form submitted successfully and email sent!");
        setIsSubmitted(true); // Disable form after submission
        onFormSubmit(); // Call the function passed from Contact component to open the popup
      })
      .catch((error) => {
        toast.error("Failed to send email. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  const inputVariants = {
    focus: { scale: 1.02, transition: { duration: 0.2 } }
  };

  return (
    <div className="relative">
      <form onSubmit={handleSubmit} className="space-y-6 font-poppins max-w-lg mx-auto bg-white shadow-lg rounded-lg p-8 border border-yellow-400">
        <h2 className="text-2xl font-semibold text-center text-yellow-600 mb-6">Contact Us</h2>

        <motion.div variants={inputVariants} whileFocus="focus">
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 flex items-center">
            <User className="mr-2 h-5 w-5 text-yellow-600" /> Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            disabled={isSubmitted || loading} // Disable input after submission or while loading
            className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
          />
        </motion.div>

        <motion.div variants={inputVariants} whileFocus="focus">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700 flex items-center">
            <Home className="mr-2 h-5 w-5 text-yellow-600" /> Address
          </label>
          <textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            disabled={isSubmitted || loading}
            className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
          />
        </motion.div>

        <motion.div variants={inputVariants} whileFocus="focus">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700 flex items-center">
            <Home className="mr-2 h-5 w-5 text-yellow-600" /> City
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            disabled={isSubmitted || loading}
            className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
          />
        </motion.div>

        <motion.div variants={inputVariants} whileFocus="focus">
          <label htmlFor="contactNo" className="block text-sm font-medium text-gray-700 flex items-center">
            <Phone className="mr-2 h-5 w-5 text-yellow-600" /> Contact No.
          </label>
          <input
            type="tel"
            id="contactNo"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
            required
            disabled={isSubmitted || loading}
            className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
          />
        </motion.div>

        <motion.div variants={inputVariants} whileFocus="focus">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 flex items-center">
            <Mail className="mr-2 h-5 w-5 text-yellow-600" /> Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={isSubmitted || loading}
            className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
          />
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <button 
            type="submit" 
            disabled={isSubmitted || loading}
            className={`w-full ${isSubmitted || loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-yellow-400 hover:bg-yellow-500'} text-black font-semibold py-1 px-4 rounded-md transition duration-300 ease-in-out transform`}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12c0-4.418 3.582-8 8-8s8 3.582-8 8H4z"></path>
                </svg>
                Submitting...
              </div>
            ) : (
              isSubmitted ? "Form Submitted" : "Submit"
            )}
          </button>
        </motion.div>
      </form>

      {/* Popup/Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
            <h2 className="text-lg font-bold mb-4 text-center">Success!</h2>
            <p className="mb-4 text-center">Your contact form has been submitted successfully!</p>
            <div className="flex justify-center">
              <button 
                onClick={closePopup}
                className="bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
