import React, { useState } from 'react';
import { toast } from 'sonner'; // For notifications
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com'; // Import EmailJS
import AnimatedComponent from '../components/AnimatedComponent'; // Assuming you have an AnimatedComponent
import { PhoneIcon, MailIcon } from 'lucide-react'; // Icons for contact information
import ScrollToTop from '../components/ScrollToTop';
import { CheckCircle } from 'lucide-react'; // Import success icon

const DistributorInquiry = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    city: '',
    phone: '',
    email: '',
    company: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [loading, setLoading] = useState(false); // Track loading state
  const [showThankYou, setShowThankYou] = useState(false); // Track showing thank you modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitted) {
      toast.error("You have already submitted the form!");
      return;
    }

    setLoading(true); // Set loading state to true

    // Sending email using EmailJS
    emailjs.send('drashti_foods', 'template_b07m2kg', formData, 'OTiHIGlSMQqreou0s')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success("Form submitted successfully and email sent!");
        setIsSubmitted(true); // Disable form after submission
        setShowThankYou(true); // Show Thank You popup
      })
      .catch((error) => {
        console.log('FAILED...', error);
        toast.error("Failed to send email. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  const inputVariants = {
    focus: { scale: 1.02, transition: { duration: 0.2 } }
  };

  const closeThankYouPopup = () => {
    setShowThankYou(false); // Close the Thank You popup
  };

  return (
    <div className="min-h-screen flex font-poppins flex-col mt-6 bg-gray-50">
      <ScrollToTop />
      <section className="bg-white">
        <div className="min-h-screen py-6 px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto bg-white rounded-lg shadow-2xl overflow-hidden"
          >
            <div className="p-8">
              <AnimatedComponent>
                <h1 className="text-4xl font-bold mb-6 text-center text-yellow-600">Distributor Inquiry</h1>
              </AnimatedComponent>

              {/* Contact Information Section */}
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-4 text-center">We're Here to Help!</h2>
                <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 text-center sm:text-left">
                  <div className="flex items-center justify-center sm:justify-start">
                    <PhoneIcon className="h-6 w-6 text-yellow-600 mr-2" />
                    <span className="text-gray-700 text-lg">+91 9825184844</span>
                  </div>
                  <div className="flex items-center justify-center sm:justify-start">
                    <MailIcon className="h-6 w-6 text-yellow-600 mr-2" />
                    <span className="text-gray-700 text-lg">drashtifoods5818@gmail.com</span>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {['fullName', 'phone', 'email', 'company', 'address', 'city'].map((field) => (
                    <motion.div key={field} variants={inputVariants} whileFocus="focus">
                      <label htmlFor={field} className="text-sm font-medium text-gray-700">{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
                      <input
                        type={field === "phone" ? "tel" : "text"}
                        id={field}
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        required
                        disabled={isSubmitted || loading}
                        placeholder={`Enter your ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                        className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
                      />
                    </motion.div>
                  ))}
                </div>

                <motion.div variants={inputVariants} whileFocus="focus">
                  <label htmlFor="message" className="text-sm font-semibold text-gray-700">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    disabled={isSubmitted || loading}
                    placeholder="Write your message here..."
                    className="mt-2 w-full border border-yellow-500 focus:border-yellow-600 focus:ring-yellow-600 px-4 py-1 text-lg rounded-md transition duration-200 ease-in-out shadow-lg"
                    rows="4"
                  ></textarea>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <button 
                    type="submit" 
                    disabled={isSubmitted || loading}
                    className={`w-full ${isSubmitted || loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-yellow-500 hover:bg-yellow-600'} text-black font-semibold py-3 rounded-md transition duration-300 ease-in-out transform`}
                  >
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <svg className="animate-spin h-5 w-5 mr-3 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                        </svg>
                        Sending...
                      </div>
                    ) : 'Submit'}
                  </button>
                </motion.div>
              </form>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Thank You Popup Modal */}
{showThankYou && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center"
    >
      <div className="flex justify-center mb-4">
        <CheckCircle className="h-12 w-12 text-yellow-500" />
      </div>
      <h2 className="text-3xl font-bold mb-2 text-yellow-600">Thank You!</h2>
      <p className="text-gray-700 mb-4">Your Distributor Inquiry has been submitted successfully. We'll get back to you shortly.</p>
      <motion.button 
        onClick={closeThankYouPopup}
        className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform shadow-lg"
      >
        Close
      </motion.button>
    </motion.div>
  </div>
)}

    </div>
  );
};

export default DistributorInquiry;
