import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import papadBgImage1 from '../assets/images/ing3.png';
import papadBgImage2 from '../assets/images/papad1.jpeg';
import papadBgImage3 from '../assets/images/image2.jpeg';
import papadBgImage4 from '../assets/images/image2.png';

// Slider data
const slides = [
  {
    id: 1,
    image: papadBgImage1,
    title: '',
    description: '',
  },
  {
    id: 2,
    image: papadBgImage2,
    title: '"Crisp, Fresh, Delicious"',
    description: 'Focuses on the genuine taste and satisfying crunch of your high-quality papads.',
  },
  {
    id: 3,
    image: papadBgImage3,
    title: '"Bringing You the Taste of Home"',
    description: 'Promises a fresh and flavorful experience with every bite, ensuring the best taste.',
  },
  {
    id: 4,
    image: papadBgImage4,
    title: '"Savor the Authentic Flavor"',
    description: 'A delightful snack that reminds you of home.',
  },
];

const Slider = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Handle slide selection
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCurrentSlide(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  // Use effect to handle events
  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onSelect]);

  // Animation variants for slides
  const slideVariants = {
    enter: { opacity: 0, scale: 0.95 },
    center: {
      zIndex: 1,
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
    exit: { opacity: 0, scale: 0.95, transition: { duration: 0.5 } },
  };

  return (
    <div className="relative w-full max-w-full h-[220px] md:h-[500px] overflow-hidden rounded-lg bg-gray-200 shadow-lg">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {slides.map((slide) => (
            <motion.div
              key={slide.id}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              className="flex-[0_0_100%] min-w-0 relative h-full"
            >
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-[220px] md:h-[500px] object-cover rounded-lg"
              />
              {/* Updated text rendering with highlight effect */}
              {slide.id !== 1 && (
                <div className="absolute inset-0 flex  flex-col justify-center items-center rounded-lg">
                  <h2 className="title  text-yellow-400 text-center font-poppins ml-5 mr-5 text-[20px] sm:text-[20px] md:text-[40px] lg:text-[50px] font-extrabold leading-normal tracking-[2px] md:tracking-[3.6px] mb-2 text-shadow-lg transition-transform duration-300 transform hover:scale-105    p-2 rounded">
                    {slides[currentSlide].title}
                  </h2>
                  <p className="details text-black-400 text-center font-poppins ml-5 mr-5 text-[15px] sm:text-[20px] md:text-[25px] font-bold leading-normal tracking-[1.5px] md:tracking-[2.04px] text-shadow-lg transition-transform duration-300 transform hover:scale-105 p-2 rounded">
                    {slides[currentSlide].description}
                  </p>
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
      {/* Removed the pagination buttons */}
    </div>
  );
};

export default Slider;
