import React from 'react';

const SectionHeader = ({ title }) => {
  return (
    <div className="relative">
      <div className="bg-yellow-400 py-2 md:py-4">
        <h2
          className="text-[#1E1E1E] text-center font-poppins font-bold tracking-[1.2px] sm:tracking-[2.4px] text-3xl sm:text-4xl md:text-[60px] leading-normal sm:leading-tight whitespace-normal" 
          
        >
          {title}
        </h2>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-2 sm:h-4 bg-gradient-to-b from-transparent to-black opacity-20"></div> 
    </div>
  );
};

export default SectionHeader;
