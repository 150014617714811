// src/pages/Home.js
import React from 'react';
import ProductList from '../components/ProductList';
import Slider from '../components/Slider';
import Title from '../components/Title';
import { Helmet } from 'react-helmet';
import ProductDescription from '../components/ProductDescription';
import group from '../assets/images/Group.png';
import FutureProducts from '../components/FutureProducts';
import { Link } from 'react-router-dom';
import { products } from '../data/products';
import { motion } from 'framer-motion'; // Import Framer Motion
import ScrollToTop from '../components/ScrollToTop';

const Home = () => {
  const firstFourProducts = products.slice(0, 4);

  return (
    <div className="min-h-screen flex flex-col mt-12 sm:mt-6">
      <ScrollToTop />
      <Helmet>
        <title>Drashti Foods - Home</title>
        <meta name="description" content="Discover a variety of delicious papads made from high-quality ingredients at Drashti Foods." />
        <meta name="keywords" content="Papad, Drashti Foods, Wheat Papad, Coin Papad, Udad Papad, Rice Papad" />
      </Helmet>

      <section className="bg-gray-100">
        <Slider />
      </section>

      <ProductDescription />

      {/* Featured Products Section */}
      <section className="bg-gray-100 py-5">
        <Title title="Featured Products" />

        {/* Animate product list on scroll */}
        <motion.div
          className="max-w-6xl mx-auto mb-10"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex justify-center items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mx-auto max-w-6xl px-4">
              <ProductList products={firstFourProducts} />
            </div>
          </div>
        </motion.div>

        <Link to="/product">
          <div className="flex flex-col items-center">
            <div className="bg-[#ffcb02] rounded-lg flex items-center justify-center px-6 py-3 transition-transform duration-300 hover:bg-[#f9b300] hover:shadow-lg cursor-pointer">
              <span className="text-black text-[20px] font-semibold font-poppins text-center">View Our Products</span>
            </div>
          </div>
        </Link>
      </section>

      {/* Other sections (Manufacturing, Future Products, etc.) */}
      <FutureProducts />

      <section className="py-8 bg-gray-100">
        <Title title="Certifications" />
        <div className="container mx-auto px-5">
          <div className="flex justify-center space-x-8">
            <div className="flex justify-center items-center">
              <img src={group} alt="group" className="object-contain  md:w-auto" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
